nav .scroll-arrow-wrapper .scroll-arrow {
  transition: all 0.2s ease-out;

  &:before {
    transition: all 0.2s ease-out;
  }
}

.scrolling {
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;

  .title {
    scroll-snap-align: start;
  }

  .about {
    scroll-snap-align: center;
  }
}
