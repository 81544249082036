.scrolling .about {
  font-family: "Roboto", sans-serif;

  .main {
    font-size: 1.6rem;
  }

  footer {
    font-size: 1rem;

    a {
      color: inherit;
    }
  }
}
