$arrow-size: 2rem;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  text-align: center;
}

// Nav bar arrow layout

nav {
  height: 5rem;
  z-index: 3;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .scroll-arrow-wrapper {
    width: $arrow-size;
    height: $arrow-size;
    cursor: pointer;

    .scroll-arrow {
      width: $arrow-size;
      height: 1px;
      transform-origin: 50% 50%;
      transform: rotate(45deg) translate(0px, $arrow-size / 2);

      &:before {
        content: " ";
        position: absolute;
        height: 1px;
        width: $arrow-size;
        top: 0;
        left: 0;
        transform-origin: 50% 50%;
        transform: rotate(-90deg) translate($arrow-size / 2, $arrow-size / 2);
      }
    }

    &.reverse-arrow .scroll-arrow {
      transform: rotate(45deg) translate($arrow-size / 2, 0px);

      &:before {
        transform: rotate(-90deg) translate(-$arrow-size / 2, -$arrow-size / 2);
      }
    }
  }
}

// Fixed section layout

.fixed {
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;

  header {
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    img {
      max-width: 85%;
      max-height: 65%;
      transform: translateY(4rem);
    }
  }

  .spacer {
    height: 8rem;
  }
}

// Scrolling section layout

.scrolling {
  height: 100%;
  z-index: 2;
  position: relative;
  overflow: scroll;

  .title {
    height: 100%;

    .spacer {
      height: calc(100% - 8rem);
    }

    .svg-wrapper {
      width: 100%;

      svg {
        max-width: 60%;
        height: 8rem;
      }
    }
  }

  .about {
    padding: 0 1.5em;
    height: 125%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .spacer .main footer {
      flex-grow: 1;
    }

    p {
      margin: 0.5rem 1rem;
    }
  }
}
