:root {
  --hero-color: #000;
  --hero-invert: 0%;
  --hero-sepia: 0%;
  --hero-saturate: 100%;
  --hero-hue: 0deg;
  --hero-brightness: 0%;
  --hero-contrast: 0%;
  --background-color: #fff;
  --title-color: #000;
}

body {
  background-color: var(--background-color);
}

nav {
  color: var(--hero-color);

  .scroll-arrow-wrapper .scroll-arrow {
    background-color: var(--hero-color);

    &:before {
      background-color: var(--hero-color);
    }
  }
}

.fixed header img {
  // The 'unquote' is a hacky workaround for a bug where scss won't compile
  // filter functions correctly.
  filter: unquote("invert(var(--hero-invert))")
    unquote("sepia(var(--hero-sepia))")
    unquote("saturate(var(--hero-saturate))")
    unquote("hue-rotate(var(--hero-hue))")
    unquote("brightness(var(--hero-brightness))")
    unquote("contrast(var(--hero-contrast))");
}

.scrolling {
  .title .svg-wrapper {
    background-color: #fff;

    svg {
      fill: var(--title-color);
    }
  }

  .about {
    color: var(--hero-color);
    background-color: var(--background-color);

    a,
    a:visited,
    a:hover,
    a:active {
      color: inherit;
    }
  }
}
